import React from "react";
import { Button } from "rsuite";
import {useNavigate, useParams} from "react-router-dom";
import {updateBlogStage, updateEventStage} from "../services/events.service";
import {toast} from 'react-toastify';
import {useSelector} from "react-redux";
const EventsItem = ({ data, type }) => {

    const navigate = useNavigate();

    let { userId } = useParams();

    const requestApproval = async() => {
        const res = await type==="event" ? updateEventStage(data._id, "Unapproved") : updateBlogStage(data._id, "Unapproved");
        console.log(res.data);
        if(res.data.status == 200){
            toast.success("Approval Request Success")
        }else{
            toast.error('error while submitting for approval')
        }
    }

    const grantApproval = async() => {

        const res = await type==="event" ? updateEventStage(data._id, "Published") : updateBlogStage(data._id, "Published");
        if(res.data.status == 200){
            toast.success("Approval Request Success")
        }else{
            toast.error('error while submitting for approval')
        }
    }

    const showComments = () => {

    }

    const profileDetails = useSelector(({ app }) => app.profileDetails);

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center border p-2 my-2 rounded">
      <h4>{data.uniqueID}</h4>
      <p className="py-2 py-md-0" >{data.title}</p>
      <div>
        {data.stage == "Draft" && (
          <>
            <Button appearance="link" color="yellow" onClick={() => navigate(type==="event" ? `/news-event-edit/${data._id}`:`/blog-edit/${data._id}`)} >
              Edit
            </Button>
            <Button appearance="link" color="yellow"  onClick={requestApproval}>
              Request Approval
            </Button>
          </>
        )}
        {data.stage == "Published" && (
          <>
            <Button appearance="link" color="green" onClick={() => navigate(`/comments/${data._id}?uid=${data.uniqueID}&total=${data.comments?.length}&type=${type}`)} >
              Show Comments
            </Button>
          </>
        )}
          {data.stage == "Unapproved" && profileDetails.type == "Super Admin" && (
              <>
                  <Button appearance="link" color="blue" onClick={grantApproval}>
                      Approve
                  </Button>
              </>
          )}
        <Button
          appearance="ghost"
          color={
            (data.stage === "Draft" && "yellow") ||
            (data.stage === "Published" && "green") ||
            (data.stage === "Unapproved" && "blue")
          }
        >
          {data.stage}
        </Button>
      </div>
    </div>
  );
};

export default EventsItem;
