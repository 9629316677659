import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    ButtonToolbar,
    Form,
    Input, Loader,
    Schema,
    SelectPicker,
} from "rsuite";
import TextInput from "../components/TextInput";
import {useNavigate, useParams} from "react-router-dom";
import TextEditorPage from "./TextEditorPage";
import {createEvent, getSpecificEvent, updateEvent, verifyEventUniqueId} from "../services/events.service";
import { handleErrorBlock } from "../utils/errorBlock";
import { MdOutlineCancel, MdTaskAlt } from "react-icons/md";
import { toast } from "react-toastify";
import { CreateFaculty } from "../services/faculty.service";
const inputWidth = {
    width: "clamp(100px, 40vw, 200px)",
};

const EditNewsEvent = () => {
    const editorRef = useRef("");
    const [validId, setValidId] = useState(null);
    const [loading,setLoading] = useState(false);
    const [formValue, setFormValue] = React.useState({
        title: "",
        excerpt: "",
        content: "",
        file: "",
        uniqueID: "",
    });
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const model = Schema.Model({
        title: Schema.Types.StringType().isRequired("Name Cannot be empty"),
        excerpt: Schema.Types.StringType().isRequired("This Field Required"),
        content: Schema.Types.StringType().isRequired("This Field is required."),
    });

    const navigate = useNavigate();
    let { id } = useParams();
    useEffect(() => {
        setLoading(true);
        (async() => {
            try {
                const res = await getSpecificEvent(id);
                let data = res.data.data
                setFormValue({
                    title: data.title,
                    excerpt: data.excerpt,
                    content: data.content,
                    uniqueID: data.uniqueID,
                    photo:data.thumbnail,
                })
                setLoading(false);
            }catch (e) {
               handleErrorBlock(e, navigate)
            }
        })()
    },[])

    const handleSubmit = async () => {
        if (!formRef.current.check() ) {
            toast.error("Please Check all the fields");
        } else {
            const data = new FormData();
            for (let item in formValue) {
                data.append(`${item}`, formValue[item]);
            }
            try {
                let res = await updateEvent(data, id);
                console.log(res);
                toast.success("Event Updated Successfully");
                setTimeout(() => {
                    navigate("/events-list");
                }, 1000);
            } catch (e) {
                handleErrorBlock(e, navigate);
            }
        }
    };

    const uploadPicture = e => {
        setFormValue({ ...formValue, file: e.target.files[0] });
    };

    return (
        <div className="p-4">
            <h1>Edit News/Event</h1>
            {loading? <Loader size="lg" content="Large" />: <div className="d-flex flex-column mb-3">
                <Form
                    ref={formRef}
                    fluid
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={model}
                    className="mt-3 "
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex flex-column mb-3">
                        <label htmlFor="">Photo</label>
                        <input type="file" onChange={uploadPicture} />
                        {formValue?.file ? (
                            <img
                                src={URL.createObjectURL(formValue.file) || formValue.photo}
                                height="200px"
                                width="200px"
                            />
                        ):<img
                            src={formValue.photo}
                            height="200px"
                            width="200px"
                        />}
                    </div>
                    <TextInput style={inputWidth} name="title" label="Title"  />
                    <TextInput style={inputWidth} name="excerpt" label="Excerpt" />
                    <label className="d-block mb-1">Content</label>
                    <TextEditorPage
                        editorRef={editorRef}
                        setFormValue={setFormValue}
                        fromValue={formValue}
                        initialContent={formValue.content}
                    />
                    <ButtonToolbar>
                        <Button appearance="primary" type="submit">
                            Submit
                        </Button>
                        <Button appearance="default" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </Form>
            </div>}

        </div>
    );
};

export default EditNewsEvent;
